module.exports = [{
      plugin: require('../node_modules/@vp/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"88967fb53eae4f199512dff552dcef57","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"development","client":{"javascript":{"source_map_enabled":true,"code_version":"1462f08c","guess_uncaught_frames":true}},"server":{"root":"webpack://brand-preferences-page/./"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
